import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'


const SectionContentHeader = ({ className, children }) => (
  <div className={classNames('section-content-header', className)}>{children}</div>
)

export default SectionContentHeader

SectionContentHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
