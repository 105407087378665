import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import SectionContentHeader from './SectionContentHeader'
import NavigationLink from './NavigationLink'

const TeaserHeader = ({ category, title, keywords, linkTo, linkTitle, hasBody, children }) => {
  return (
    <SectionContentHeader
      className={classNames({ 'decor': hasBody, 'mb-0': !hasBody, 'pb-0': children === undefined })}>
      <div className="row">
        <div className={classNames('col-12 text-left', { 'col-lg-4': children !== undefined })}>
          <div className="title-desc pr-4">
            {category &&
            <h4 className="display-6">{category}</h4>
            }
            <h2 className={classNames('h1 m-0 pb-3', { 'mb-md-2 mb-5': linkTo !== undefined })}>{title}</h2>
            {keywords &&
            <p>{keywords}</p>
            }
          </div>
          {linkTo && linkTitle &&
          <div className="d-none d-lg-block btns-action mb-3">
            <NavigationLink to={linkTo}>{linkTitle}</NavigationLink>
          </div>
          }
        </div>
        {children &&
        <div className="col-12 col-lg-8 text-left">
          <div className="title-desc">{children}</div>
          {linkTo && linkTitle &&
          <div className="d-lg-none btns-action mb-3">
            <NavigationLink to={linkTo}>{linkTitle}</NavigationLink>
          </div>
          }
        </div>
        }
      </div>
    </SectionContentHeader>
  )
}

export default TeaserHeader

TeaserHeader.propTypes = {
  category: PropTypes.string,
  keywords: PropTypes.string,
  linkTitle: PropTypes.string,
  linkTo: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  hasBody: PropTypes.bool,
}
