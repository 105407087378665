import * as PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DefaultSection from './DefaultSection'
import IconTeaser from './IconTeaser'

const IndividualContactSection = ({ personKey, title, teaserText, email, tel, bold = false, className }) => {

  const data = useStaticQuery(graphql`
      query PersonQuery {
          persons: allPersonsJson {
              edges {
                  node {
                      key
                      firstName
                      lastName
                      email
                      tel
                      image
                  }
              }
          }
      }
  `)

  const personData = data.persons.edges.find((e) => e.node.key === personKey).node
  const personFullName = personData.firstName + ' ' + personData.lastName
  const mail = email ? email : personData.email
  const phone = tel ? tel : personData.tel
  return (
    <DefaultSection className={className}>
      <h1 className="pb-5 d-md-none">{title}</h1>
      <IconTeaser imageRelativePath={personData.image}
                  iconImageAlt={'Abbildung: ' + personFullName}>
        <h1 className="pb-5 d-none d-md-block">{title}</h1>
        {teaserText &&
        <p>{teaserText}</p>
        }
        {bold ?
          <h1 className="display-title home-title" style={{ 'font-size': '3.285em' }}>{personFullName}</h1>
          :
          <h4 className="display-5">{personFullName}</h4>
        }
        <p className="display-decor mt-0">
          {personData.tel &&
          <React.Fragment>
            <span>Telefon: <a className="phone-link" aria-label={'Telefonnummer des/der AnsprechpartnerIn'}
                              href={'tel:' + phone.replace(/\s/g, '')}>{phone}</a></span><br/>
          </React.Fragment>
          }
          <span>E-Mail: <a className="phone-link" href={'mailto:' + mail}
                           aria-label={'E-Mail-Kontakt BUSCHMAIS'}>{mail}</a></span>
        </p>
      </IconTeaser>
    </DefaultSection>
  )
}

export default IndividualContactSection

IndividualContactSection.propTypes = {
  personKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  teaserText: PropTypes.string,
  email: PropTypes.string,
  tel: PropTypes.string,
  className: PropTypes.string,
}

IndividualContactSection.defaultProps = {
  title: 'Ihr Ansprechpartner',
}
