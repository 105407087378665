export const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]
export const formatDate = (jsDate) => {
  return jsDate.getDate() + '. ' + months[jsDate.getMonth()] + ' ' + jsDate.getFullYear()
}

export const formatDateShort = (jsDate) => {
  const month = String(jsDate.getMonth() + 1).padStart(2, '0')
  const day = String(jsDate.getDate()).padStart(2, '0')
  const year = jsDate.getFullYear()
  return day + '.' + month + '.' + year
}

export const formatDateRange = (start, end) => {
  if (datesAreOnSameDay(start, end)) {
    return formatDate(start)
  } else if (datesAreOnSameMonth(start, end)) {
    return start.getDate() + '. bis ' + end.getDate() + '. ' + months[start.getMonth()] + ' ' + start.getFullYear()
  } else {
    return formatDate(start) + ' bis ' + formatDate(end)
  }
}

export const formatTimeRange = (start, end) => {
  const options = { hour: '2-digit', minute: '2-digit' }
  return start.toLocaleTimeString([], options) + ' - ' + end.toLocaleTimeString([], options)
}

export const datesAreOnSameDay = (first, second) => {
  return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
}

export const datesAreOnSameMonth = (first, second) => {
  return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth()
}

export const toDate = (dateString) => {
  if (dateString.indexOf('T') === -1) {
    return new Date(dateString + 'T00:00:00')
  } else {
    return new Date(dateString)
  }
}
