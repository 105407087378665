import * as PropTypes from 'prop-types'
import React from 'react'

const Badge = ({ title, color = 'dark' }) => (
  <div className={'badge mr-2 badge-' + color}>{title}</div>
)

export default Badge

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['dark', 'warning', 'brand']),
}
