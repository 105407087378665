import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import TeaserBody from '../../components/TeaserBody'
import NavigationLink from '../../components/NavigationLink'
import IndividualContactSection from '../../components/IndividualContactSection'
import { graphql } from 'gatsby'
import Schedule from '../../components/Schedule'
import Card from '../../components/Card'
import CardDeck from '../../components/CardDeck'

const Page = ({ data }) => {

  const now = new Date()
  const schedules = data.allTermineJson.edges
    .filter(scheduleNode => new Date(scheduleNode.schedule.endDate) >= now)
    .map(scheduleNode => scheduleNode.schedule)

  return (
    <React.Fragment>
      <Seo title="Einführung in jQAssistant - Workshop"
           description="Lernen Sie alle Schritte von der Integration von jQAssistant in das Projekt, über die Einsatzmöglichkeiten im Rahmen von Software Analytics bis zu den Möglichkeiten zur Absicherung und Erstellung lebendiger, selbstvalidierender Dokumentation kennen."
      />

      <BigTitle title="Einführung in jQAssistant"
                className="mask-black" textInverted={true}
                imageRelativePath={'Titelbild_Architektur.jpg'}
                menuInverted={true} logoInverted={true}>
        - Workshop -
      </BigTitle>
      <DefaultSection>
        <Article>
          <h2 className="h1">Erhöhte Planungssicherheit mit jQAssistant</h2>
          <p>
            Softwaresysteme sind komplex. Egal, ob es darum geht, Refaktorisierungen zu planen oder neue Features
            umzusetzen, früher oder später wäre eine Sicht jenseits der IDE auf die Softwarestrukturen hilfreich. Noch
            besser wäre es, wenn implementierte Strukturen der Soll- Architektur folgen und auch in Zukunft ein
            Erodieren der Softwarestrukturen automatisiert verhindert wird. Mit dem Open-Source Tool jQAssistant ist
            genau dies möglich.
          </p>
          <p>
            Lernen Sie alle Schritte von der Integration von jQAssistant in das Projekt, über die Einsatzmöglichkeiten
            im Rahmen von Software Analytics bis zu den Möglichkeiten zur Absicherung und Erstellung lebendiger,
            selbstvalidierender Dokumentation kennen. Egal, ob Sie jQAssistant noch nicht kennen oder bereits damit
            gearbeitet haben und lernen möchten, wie Sie es effektiv in Ihrem Projekt einsetzen können, ist dieser
            Workshop das Richtige für Sie!
          </p>
          <p className="text-center-sm">
            <NavigationLink to={'#termine'} asActionButton buttonClassName="btn-outline-black">Termin
              auswählen</NavigationLink></p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-2">

        <CardDeck cardsPerRow={2}>
          <Card>
            <p className="subTitle text-muted">Zielgruppe</p>
            <h2>Wer sollte teilnehmen?</h2>

            <p className="mt-4">
              Alle Personen mit Hintergrund aus der Softwareentwicklung oder -architektur, welche noch nicht mit
              jQAssistant gearbeitet haben oder bereits erste Schritte gegangen sind, aber die vollen Möglichkeiten im
              professionellen Einsatz kennenlernen möchten.
            </p>
          </Card>
          <Card>
            <p className="subTitle text-muted">Vorkenntnisse und Anforderungen</p>
            <h2>Was wird benötigt?</h2>
            <p>
              <ul>
                <li>sicherer Umgang mit einer IDE, Git und Maven</li>
                <li>solides Wissen über Java</li>
                <li>eigenes entwicklungsgeeignetes Notebook mit IDE</li>
              </ul>
            </p>
          </Card>
        </CardDeck>

      </DefaultSection>
      <DefaultSection>
        <Article>
          <h2 className="h1">Inhalte</h2>
          <p>Am ersten Tag erhalten Sie einen Überblick über das Open Source-Tool jQAssistant, lernen typische
            Anwendungsfälle kennen und bekommen gezeigt, wie sich jQAssistant in Projekte integrieren lässt. Im Rahmen
            der Softwareanalyse erlernen Sie die Abfragesprache Cypher.</p>
          <p>Am zweiten Tag steigen Sie tiefer in die Analyse von Softwaresystemen ein. Es wird gezeigt, welche
            Anwendungsfälle sich dafür ergeben und wie bereits vorhandene Informationen genutzt werden können, um
            Schlüsse über die Architektur und etwaige Hot Spots abzuleiten. Anschließend betrachten wir, wie mit
            jQAssistant bestehende und neue Architekturregeln abgesichert und eingeführt werden können, um so die
            Architektur langfristig vor einer Erosion zu bewahren.</p>
          <p>Am dritten Tag führen wir die erlernten Fähigkeiten bei der Erstellung lebendiger Architekturdokumentation
            zusammen. Es wird betrachtet, wie mit jQAssistant Dokumentation erstellt werden kann, wie diese um sich
            selbst aktualisierende Aspekte erweitert werden kann und welche Art von Dokumentation sich dafür anbietet.
            Zum Abschluss des Workshops besteht die Möglichkeit, offene Fragen zu diskutieren und eigene Anwendungsfälle
            zu betrachten.</p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-2" anchor={'termine'}>
        <TeaserHeader hasBody title="Termine">
          <p>
            Wählen Sie aus den angebotenen Terminen den für Sie passenden oder kontaktieren Sie uns. Gern erstellen wir
            Ihnen ein individuelles Angebot, das zeitlich besser für Sie passt.
          </p>
        </TeaserHeader>
        <TeaserBody>
          {schedules && schedules.map(schedule => (
            <div className="mb-5" key={schedule.id}>
              <Schedule schedule={schedule} showDetails/>
            </div>
          ))}
          <p>
            Gerne können Sie den Workshop auch inhouse für Ihr ganzes Team buchen. Für Gruppenrabatte kommen Sie bitte
            telefonisch unter <a className="phone-link"
                                 aria-label="Telefonnummer BUSCHMAIS GbR"
                                 href="tel:+493513209230">+49 351 3209230</a> oder
            per E-Mail an <a
            className="phone-link" href="mailto:info@buschmais.com"
            aria-label="E-Mail-Kontakt BUSCHMAIS">info@buschmais.com</a> auf uns zu.<br/><br/>Wir freuen uns, von Ihnen
            zu
            hören.
          </p>
        </TeaserBody>
      </TeaserSection>

      <IndividualContactSection personKey={'stephan'}/>

    </React.Fragment>
  )
}

export default Page

export const query = graphql`
    {
        allTermineJson(sort: {fields: startDate}, filter: {eventKey: {eq: "workshop-jqa"}}) {
            edges {
                schedule: node {
                    id
                    title
                    category
                    labels
                    descriptionShort
                    startDate
                    endDate
                    personKeys
                    locationKey
                    linkDetails
                    linkBooking
                    price
                    capacity
                }
            }
        }
    }
`
