import React from 'react'
import Image from './Image'

const IconTeaser = ({ imageRelativePath, iconImageAlt, children }) => (
  <div className="row align-items-center">
    <div className="col-12 col-md-4 pr-md-5 pb-5 pb-md-0">
      <div className="w-75 w-md-100">
        <Image relativePath={imageRelativePath} alt={iconImageAlt}/>
      </div>
    </div>
    <div className="col-12 col-md-7">{children}</div>
  </div>
)

export default IconTeaser
