import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'


const Article = ({ className, children }) => (
  <div className={classNames('article article-light', className)}>{children}</div>
)

export default Article

Article.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
