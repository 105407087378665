import React from 'react'
import './Schedule.scss'
import Badge from './Badge'
import { graphql, useStaticQuery } from 'gatsby'
import NavigationLink from './NavigationLink'
import { formatDateRange, formatTimeRange } from '../common/dateFunctions'
import Image from './Image'

const Schedule = ({ schedule, showDetails, hostTitle = 'Trainer' }) => {

  const data = useStaticQuery(graphql`
      query SchedulePersonQuery {
          persons: allPersonsJson {
              edges {
                  node {
                      id
                      key
                      firstName
                      lastName
                      email
                      tel
                      image
                      bio
                  }
              }
          }
      }
  `)

  const persons = schedule.personKeys.map(key => data.persons.edges.find((p => p.node.key === key)).node)
  const startDate = new Date(schedule.startDate)
  const endDate = new Date(schedule.endDate)

  let followUpLink = <span/>
  if (showDetails && schedule.linkBooking) {
    followUpLink = <NavigationLink to={schedule.linkBooking}>Zur Anmeldung</NavigationLink>
  } else if (!showDetails && schedule.linkDetails) {
    followUpLink = <NavigationLink to={schedule.linkDetails}>Details</NavigationLink>
  } else if (!showDetails && schedule.linkBooking) {
    followUpLink = <NavigationLink to={schedule.linkBooking}>Zur Anmeldung</NavigationLink>
  }

  return (
    <div className="w-100">
      <div className="d-flex flex-row align-items-center justify-content-between bg-brand text-white py-3 px-3 px-md-4">
        <div>
          <h5 className="m-0">{formatDateRange(startDate, endDate)}</h5>
        </div>
        <div className="h5 m-0 text-right d-none d-md-block">
          <Badge title={schedule.category}/>
          {schedule.labels && schedule.labels.split(' ')
            .map(label => <Badge title={label} color={'light'}/>)
          }
        </div>
      </div>
      <div className="bg-level-1 py-3 px-3 px-md-4 w-100">
        <div>
          <div className="h5 m-0 mb-2 d-md-none">
            <Badge title={schedule.category}/>
            {schedule.labels && schedule.labels.split(' ')
              .map(label => <Badge title={label} color={'brand'}/>)
            }
          </div>
          <h3 className="mb-3">{schedule.title}</h3>
          <p>{schedule.descriptionShort} | {formatTimeRange(startDate, endDate)} Uhr
            {Intl && Intl.DateTimeFormat().resolvedOptions().timeZone !== 'Europe/Berlin' &&
            <span>&nbsp;(Europe/Berlin)</span>
            }
          </p>
        </div>
        {showDetails &&
        <div className="schedule-detail">
          <dl>
            {schedule.price &&
            <>
              <dt>Preis</dt>
              <dd>{schedule.price}</dd>
            </>
            }
            {persons && persons.length > 0 &&
            <>
              <dt>{hostTitle}</dt>
              <dd>
                {persons.map((person, i) => (
                  <>
                    {i > 0 && ', '}
                    <span>{person.firstName} {person.lastName}</span>
                  </>
                ))}
              </dd>
            </>
            }
            {schedule.capacity &&
            <>
              <dt>Kapazität</dt>
              <dd>{schedule.capacity}</dd>
            </>
            }
          </dl>
        </div>
        }
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="d-flex flex-column flex-md-row">
              {!showDetails && persons.map(person => (
                <div className="d-flex flex-row align-items-bottom my-3 mr-5" key={person.id}>
                  <div style={{ width: '5rem' }}>
                    <Image relativePath={person.image} alt={person.firstName + ' ' + person.lastName}/>
                  </div>
                  <div className="pl-3">
                    <p className="schedule-staff-name">{person.firstName}<br/>{person.lastName}</p>
                  </div>
                </div>
              ))
              }
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="h-100 d-flex flex-row align-items-bottom justify-content-md-end">
              <div className="mb-3 mt-5">{followUpLink}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Schedule
