import * as PropTypes from 'prop-types'
import React from 'react'
import Section from './Section'
import SectionWrapper from './SectionWrapper'
import SectionContent from './SectionContent'
import classNames from 'classnames'
import BackgroundAwareness from './BackgroundAwareness'

const TeaserSection = ({ logoInverted, menuInverted, className, anchor, disableTopMargin, children }) => (
  <BackgroundAwareness menuInverted={menuInverted} logoInverted={logoInverted}>
    <Section className={classNames('fullscreen-md-off fp-auto-height-responsive no-footer', className)} id={anchor}>
      <SectionWrapper className={classNames('with-margin', { 'pt-0 pt-md-3': disableTopMargin })}>
        <SectionContent>
          {children}
        </SectionContent>
      </SectionWrapper>
    </Section>
  </BackgroundAwareness>
)

export default TeaserSection

TeaserSection.propTypes = {
  className: PropTypes.string,
  anchor: PropTypes.string,
  children: PropTypes.node,
  menuInverted: PropTypes.bool,
  disableTopMargin: PropTypes.bool,
  logoInverted: PropTypes.bool,
}

TeaserSection.defaultProps = {
  logoInverted: false,
  menuInverted: false,
}
