import * as PropTypes from 'prop-types'
import React from 'react'
import './BigTitle.scss'
import Section from './Section'
import SectionCover from './SectionCover'
import SectionWrapper from './SectionWrapper'
import SectionContent from './SectionContent'
import classNames from 'classnames'
import BackgroundAwareness from './BackgroundAwareness'

const BigTitle = ({
                    title,
                    imageRelativePath,
                    backgroundColor,
                    textInverted,
                    logoInverted,
                    menuInverted,
                    className,
                    children,
                  }) => (
  <BackgroundAwareness menuInverted={menuInverted} logoInverted={logoInverted}>
    <Section className={classNames('big-title', { 'content-white': textInverted })}>
      <SectionCover className={classNames('section-cover  item-cover', className)}
                    imageRelativePath={imageRelativePath} backgroundColor={backgroundColor}/>
      <SectionWrapper className="with-margin">
        <SectionContent>
          <div className="row">
            <div className="col">
              <div className="text-center">
                <h1 className="headline-super">{title}</h1>
                <p className="intro">{children}</p>
              </div>
            </div>
          </div>
        </SectionContent>
      </SectionWrapper>
    </Section>
  </BackgroundAwareness>
)

export default BigTitle

BigTitle.propTypes = {
  backgroundColor: PropTypes.string,
  imageRelativePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  textInverted: PropTypes.bool,
  menuInverted: PropTypes.bool,
  logoInverted: PropTypes.bool,
  title: PropTypes.string,
}

BigTitle.defaultProps = {
  logoInverted: false,
  menuInverted: false,
}
