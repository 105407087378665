import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade'

const CardDeck = ({ cardsPerRow = 1, className, animateScrollIntoView = false, children }) => {
  const childArray = children !== null && children !== undefined ? React.Children.toArray(children).filter(c => c !== null && c !== undefined) : []
  const cardChildren =
    <div className={classNames('row', className)}>{
      React.Children.map(childArray, (child) =>
        React.cloneElement(child, { cardsPerRow }),
      )
    }</div>
  return (animateScrollIntoView ? <Fade bottom distance={'50px'}>{cardChildren}</Fade> : cardChildren)
}

export default CardDeck

CardDeck.propTypes = {
  animateScrollIntoView: PropTypes.bool,
  cardsPerRow: PropTypes.oneOf([1, 2, 3, 4]),
  children: PropTypes.any,
  className: PropTypes.string,
}

CardDeck.defaultProps = {
  animateScrollIntoView: false,
  cardsPerRow: 1,
}
