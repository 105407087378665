import PropTypes from 'prop-types'
import React from 'react'
import Image from './Image'

const Card = ({ imageRelativePath, imageAlt, imagePos = 'top', cardsPerRow = 1, footer, children }) => {
  const columnClasses = 'col-12 col-lg-' + (12 / cardsPerRow)
  return (
    <div className={'mb-5 ' + columnClasses}>
      <div className="card border-0 bg-level-0 h-100">
        {imageRelativePath && imagePos === 'top' &&
          <Image className="card-img-top" relativePath={imageRelativePath} alt={imageAlt}/>
        }
        <div className="card-body">
          {cardsPerRow < 3 ?
            <div className="m-md-4">
              {children}
            </div>
            :
            <div>
              {children}
            </div>
          }
        </div>
        {footer &&
          <div className="card-footer border-0 bg-level-0">
            {footer}
          </div>
        }
        {imageRelativePath && imagePos === 'bottom' &&
          <Image className="card-img-bottom" relativePath={imageRelativePath} alt={imageAlt}/>
        }
      </div>
    </div>
  )
}

export default Card

Card.propTypes = {
  children: PropTypes.node,
  imageAlt: PropTypes.string,
  imageRelativePath: PropTypes.string,
  imagePos: PropTypes.oneOf(['top', 'bottom']),
}

Card.defaultProps = {
  imagePos: 'top',
}
